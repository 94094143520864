import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout.js'

const NotFoundPage = ({ data, location }) => {
  if (data.markdownRemark === null) {
    data.frontmatter = {
      title: 'Error 404',
      description: 'Whoops, not found',
    }
  }
  return (
    <Layout currentPath={location.pathname} frontmatter={data.frontmatter} error404={true}>
      <div className="o-layout o-layout--grids">
        <div className="o-layout__item u-6 u-offset-3">
          <img
            src="https://media.giphy.com/media/pmgkCpOpwCQ6c/200w.webp"
            alt="Whoops"
            style={{ width: '300px' }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
        layout
      }
    }
  }
`

export default NotFoundPage
